import React from 'react';
import { AppInteractionData, InteractionType } from 'src/types/core';
import { OrganizationApp } from 'src/reducers/organizations';
import { TargetingAppsSection } from './targeting-apps-section';
import { CriteriaUnifiedAttributes, CriteriaUnifiedTargeting } from './multi-app-segment-builder/types';
import { InteractionSection } from '../creation';

interface ImtTargetingProps {
  apps: OrganizationApp[];
  interactionData: AppInteractionData[];
  type: InteractionType;
  startTime?: string | null;
  selfTargeting?: boolean;
  unifiedAttributes?: CriteriaUnifiedAttributes;
  unifiedTargeting?: CriteriaUnifiedTargeting;
  hasResponses?: boolean;
  isTriggeredBy?: boolean;
  setSection: (section: InteractionSection) => void;
  children: React.ReactNode;
}

export const ImtTargeting: React.FC<ImtTargetingProps> = ({
  apps = [],
  interactionData = [],
  type,
  startTime,
  selfTargeting,
  unifiedAttributes,
  unifiedTargeting,
  hasResponses,
  isTriggeredBy,
  setSection,
  children,
}) => {
  return (
    <>
      <TargetingAppsSection
        apps={apps}
        interactionData={interactionData}
        selfTargeting={selfTargeting}
        type={type}
        startTime={startTime}
        unifiedAttributes={unifiedAttributes}
        unifiedTargeting={unifiedTargeting}
        hasResponses={hasResponses}
        isTriggeredBy={isTriggeredBy}
        setSection={setSection}
      />
      {children}
    </>
  );
};
