import { GetState } from 'src/reducers';
import { CustomDataAttribute, CustomDataEntity } from 'src/reducers/apps.types';
import { getCurrentAppId, getCurrentOrgId } from 'src/selectors/current_app';
import { AttributesServiceV2 } from 'src/services';
import { GetAttrResp, UpdAttrPayload } from 'src/services/attributes-service-v2';
import { AppDataActions, AppDataDispatch, CustomDataByApp } from './app-data';

export const attributes = {
  getSuggestions: () => {
    return async (dispatch: AppDataDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      dispatch({ type: AppDataActions.GET_SUGGESTED_ATTRIBUTES_PENDING, payload: { appId } });

      return AttributesServiceV2.getSuggestedAttr(appId)
        .then((resp) =>
          dispatch({ type: AppDataActions.GET_SUGGESTED_ATTRIBUTES_SUCCESS, payload: { appId, body: resp } as any }),
        )
        .catch(() => dispatch({ type: AppDataActions.GET_SUGGESTED_ATTRIBUTES_FAILURE, payload: { appId } }));
    };
  },

  getKeys: () => {
    return async (dispatch: AppDataDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      dispatch({ type: AppDataActions.GET_ATTRIBUTES_PENDING, payload: { appId } });

      const isCustomDta = (item: string) => item.startsWith('custom_data.');
      return Promise.allSettled([AttributesServiceV2.getAttr(appId, true), AttributesServiceV2.getAttr(appId, false)])
        .then((resp) => {
          const [person, device] = resp as { value: GetAttrResp }[];
          const personActiveCustomData = Object.fromEntries(
            Object.keys(person.value.keys || {})
              .filter((el) => person.value.keys[el].is_visible && isCustomDta(el))
              .map((key) => [key, person.value.keys[key]]),
          ) as Record<string, CustomDataAttribute>;

          const personArchievedCustomData = Object.fromEntries(
            Object.keys(person.value.keys || {})
              .filter((el) => !person.value.keys[el].is_visible && isCustomDta(el))
              .map((key) => [key, person.value.keys[key]]),
          ) as Record<string, CustomDataAttribute>;

          const deviceActiveCustomData = Object.fromEntries(
            Object.keys(device.value.keys || {})
              .filter((el) => device.value.keys[el].is_visible && isCustomDta(el))
              .map((key) => [key, device.value.keys[key]]),
          ) as Record<string, CustomDataAttribute>;

          const deviceArchievedCustomData = Object.fromEntries(
            Object.keys(device.value.keys || {})
              .filter((el) => !device.value.keys[el].is_visible && isCustomDta(el))
              .map((key) => [key, device.value.keys[key]]),
          ) as Record<string, CustomDataAttribute>;

          dispatch({
            type: AppDataActions.GET_ATTRIBUTES_SUCCESS,
            payload: {
              appId,
              personActiveCustomData,
              personArchievedCustomData,
              deviceActiveCustomData,
              deviceArchievedCustomData,
            },
          });
        })
        .catch(() => dispatch({ type: AppDataActions.GET_ATTRIBUTES_FAILURE, payload: { appId } }));
    };
  },

  updateKeys: (data: UpdAttrPayload = { person: [], device: [] }) => {
    return async (dispatch: AppDataDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      const personKeys = data.person.map((el) => el.key);
      const deviceKeys = data.device.map((el) => el.key);
      dispatch({ type: AppDataActions.UPDATE_ATTRIBUTES_PENDING, payload: { appId, personKeys, deviceKeys } });

      return AttributesServiceV2.updAttr(appId, data)
        .then(() => dispatch({ type: AppDataActions.UPDATE_ATTRIBUTES_SUCCESS, payload: { appId, body: data } }))
        .catch(() => dispatch({ type: AppDataActions.UPDATE_ATTRIBUTES_FAILURE, payload: { appId } }));
    };
  },

  getMultiAppAttrKeys: (appIds: string[]) => {
    return async (dispatch: AppDataDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      dispatch({ type: AppDataActions.GET_MULTI_APP_ATTRIBUTES_PENDING, payload: { orgId, appIds } });

      const isCustomData = (item: string) => item.startsWith('custom_data.');
      return Promise.all([
        AttributesServiceV2.getMultiAppAttrKeys(orgId, 'person', appIds),
        AttributesServiceV2.getMultiAppAttrKeys(orgId, 'device', appIds),
      ])
        .then((resp) => {
          const [personApps, deviceApps] = resp;

          if (personApps.length !== deviceApps.length) {
            throw new Error('Did not receive custom data for both device and person for all apps');
          }

          const customDataByApp: CustomDataByApp = {};

          for (const personApp of personApps) {
            customDataByApp[personApp.app_id] = Object.entries(personApp.keys || {})
              .filter(([key]) => isCustomData(key))
              .map(([key, el]: [string, CustomDataAttribute]) => ({
                key,
                entity: 'person' as CustomDataEntity,
                ...el,
              }));
          }

          for (const deviceApp of deviceApps) {
            if (!customDataByApp[deviceApp.app_id]) {
              throw new Error('Did not receive custom data for both device and person for all apps');
            }

            customDataByApp[deviceApp.app_id].push(
              ...Object.entries(deviceApp.keys || {})
                .filter(([key]) => isCustomData(key))
                .map(([key, el]: [string, CustomDataAttribute]) => ({
                  key,
                  entity: 'device' as CustomDataEntity,
                  ...el,
                })),
            );
          }

          dispatch({
            type: AppDataActions.GET_MULTI_APP_ATTRIBUTES_SUCCESS,
            payload: {
              orgId,
              customDataByApp,
            },
          });
        })
        .catch(() => dispatch({ type: AppDataActions.GET_MULTI_APP_ATTRIBUTES_FAILURE, payload: { orgId, appIds } }));
    };
  },
};
