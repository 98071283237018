/* eslint-disable no-use-before-define, @typescript-eslint/no-use-before-define */

import {
  SurveyQuestionType,
  QuestionAnswerChoice,
  InteractionItem,
  ISurveyQuestionSet,
  InteractionType,
  AppInteractionData,
  TargetingCriteria,
} from 'src/types/core';

export interface MultiAppsSurvey {
  id: string;
  type: InteractionType;
  orgId: string;
  createdBy: string;
  interactionData: AppInteractionData[];
  questionSetAttributes: ISurveyQuestionSet[];
  required?: boolean;
  name: string;
  title?: string;
  description?: string | null;
  showDisclaimer?: boolean;
  showSuccessMessage?: boolean;
  showTermsAndConditions?: boolean;
  styleName?: string;
  submitButtonText?: string;
  successButtonText?: string;
  successMessage?: string;
  termsAndConditionsLabel?: string;
  termsAndConditionsLink?: string;
  fromTemplate?: string;
  templateLocked?: boolean;
  respMax?: number | null;
  endTime?: InteractionItem['end_time'];
  startTime?: InteractionItem['start_time'];
  viewLimit?: number;
  viewPeriod?: number;
  nextButtonText: string;
  renderAs: string;
  displayType?: string;
  createdAt?: string;
  updatedAt?: string;
  selfTargeting?: boolean;
  contactUrl?: string;
  contactUrlText?: string;
  disclaimerText?: string;
  date?: string;
  terms?: string;
  minApiVersion?: number;
  displayResponseRate?: string;
  introButtonText?: string;
  criteria?: TargetingCriteria;
}

export { SurveyQuestionType, QuestionAnswerChoice };

export interface SurveyResponseStat {
  id: string;
  answer_count: number;
  answers: { choice: string; count: number }[];
  other_choice: boolean;
  other_text_count?: number;
  question: string;
  required: boolean;
  type: SurveyQuestionType;
}

export interface InteractionDataResponseStat {
  app_id: string;
  survey_id: string;
  stats: SurveyResponseStat[];
}

export interface SurveyTextAnswers
  extends Pick<SurveyResponseStat, 'answer_count' | 'other_choice' | 'required' | 'type'> {
  id: string; // QuestionId
  answers: { app_id: string; choice: string; created_at: number; qm_session?: string }[];
  question: string;

  // Pagination data for answers
  has_more: boolean;
  limit: number;
  page: number;
}

export interface FetchSurveysMultiAppsResp {
  surveys: MultiAppsSurvey[];
  page: number;
  total: number;
  limit: number;
  active: boolean;
  has_more: boolean;
}

export enum SurveyRenderAs {
  LIST = 'list',
  PAGED = 'paged',
}

type SurveyId = string;
type AppId = string;

export interface SurveyStatsData {
  stats?: SurveyResponseStat[];
  textAnswers?: Record<string, SurveyTextAnswers>;
}

export type AppStats = Record<AppId, SurveyStatsData>;

export type SurveyStatistics = Partial<Record<SurveyId, { data: AppStats }>>;
