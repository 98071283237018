import React, { useCallback, useLayoutEffect, useRef, ChangeEvent } from 'react';
import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';
import { InputTextProps } from './input-text';

import './input-checkbox.scss';

export interface InputCheckboxProps<T = any>
  extends Pick<
    InputTextProps<T>,
    'className' | 'value' | 'label' | 'name' | 'disabled' | 'required' | 'onMouseEnter' | 'onMouseLeave'
  > {
  checked?: boolean;
  labelRef?: React.RefObject<HTMLLabelElement>;
  gtmId?: GtmID;
  onChange(checked: boolean, value: T): void;
  title?: string;
  children?: React.ReactNode;
  hintText?: string;
}

const bem = bemPrefix('app-input-checkbox');
const noop = () => null;

export const InputCheckbox: React.FC<InputCheckboxProps> & { selectorCheckbox: string } = ({
  className = '',
  labelRef,
  label,
  gtmId,
  value,
  name,
  checked,
  disabled,
  children,
  required,
  title,
  hintText = '',
  onChange = noop,
  onMouseEnter,
  onMouseLeave,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, value),
    [onChange, value],
  );

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.defaultChecked = !!checked;
    }
  }, [inputRef.current, checked]);

  return (
    <label
      className={`${bem()} ${className} ${disabled ? 'disabled' : ''}`}
      ref={labelRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      gtm-id={gtmId}
      title={title}
    >
      <input
        ref={inputRef}
        className={bem('input')}
        type="checkbox"
        onChange={onInputChange}
        checked={checked}
        value={value}
        name={name}
        disabled={disabled}
        required={required}
      />
      <span className={bem('custom-checkbox', disabled ? 'disabled' : '')} title={hintText} />
      {label || children}
    </label>
  );
};

InputCheckbox.displayName = 'InputCheckbox';
InputCheckbox.selectorCheckbox = `.${bem('input')}`;
