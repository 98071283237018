import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'ramda';

import { OrganizationApp } from 'src/reducers/organizations';
import { Icon, Subtitle } from 'src/components/molecules';
import { bemPrefix, Time } from 'src/utils';
import { sortBy, SortOrder } from 'src/utils/sort';
import { AppInteractionData, InteractionType, TargetingCriteria } from 'src/types/core';
import { CriteriaUnifiedAttributes, CriteriaUnifiedTargeting } from './multi-app-segment-builder/types';
import { CriteriaDescription } from './multi-app-segment-builder/descriptions/criteria-description';
import { injestCriteria } from './multi-app-segment-builder/util/parse';
import { InteractionSection } from '../creation/interaction-steps';

import './targeting-apps-section.scss';

const bem = bemPrefix('targeting-apps');

const columnTitles = [
  { title: 'App Name', key: 'title', sortable: true },
  { title: 'Type', key: 'platform', sortable: true },
];

/**
 * NOTE: This intentionally takes the criteria from a different place than <TargetingWhoSettings />.
 *
 * This section is intended to display the per-app version of the targeting criteria, which will be
 * a subset of the unified criteria due to platform/app limitations.
 */

export interface TargetingAppsSectionProps {
  apps?: OrganizationApp[];
  type: InteractionType;
  interactionData: AppInteractionData[];
  selfTargeting: boolean | undefined;
  startTime?: string | null;
  unifiedAttributes?: CriteriaUnifiedAttributes;
  unifiedTargeting?: CriteriaUnifiedTargeting;
  hasResponses?: boolean;
  isTriggeredBy?: boolean;
  setSection: (section: InteractionSection) => void;
}

export const TargetingAppsSection: React.FC<TargetingAppsSectionProps> = ({
  apps = [],
  type,
  interactionData = [],
  selfTargeting,
  startTime,
  unifiedAttributes,
  unifiedTargeting,
  hasResponses,
  isTriggeredBy,
  setSection,
}) => {
  const [sortConfig, setSortConfig] = useState<{ key: keyof OrganizationApp; direction: SortOrder }>({
    key: 'title',
    direction: SortOrder.asc,
  });

  const isPrompt = type === InteractionType.TextModal;
  const isLocked = hasResponses || isTriggeredBy;

  const onSortClick = (key: keyof OrganizationApp) => {
    if (!columnTitles.find((column) => column.key === key)?.sortable) {
      return;
    }
    let direction: SortOrder = SortOrder.asc;
    if (sortConfig && sortConfig.key === key && sortConfig.direction === SortOrder.asc) {
      direction = SortOrder.desc;
    }
    setSortConfig({ key, direction });
  };

  const sortedApps = useMemo(() => {
    return sortBy(apps, sortConfig.key, sortConfig.direction);
  }, [apps, sortConfig]);

  const getCodePoints = useCallback(
    (appId: string) => {
      const codePoints = interactionData.find((data) => data.appId === appId)?.codePoints ?? [];
      if (!codePoints.length) {
        return '--';
      }
      if (Array.isArray(codePoints)) {
        return codePoints.map((codePoint) => codePoint.replace('local#app#', '')).join(', ');
      }
      return codePoints.replace('local#app#', '');
    },
    [interactionData],
  );

  const getCriteria = useCallback(
    (appId: string) => {
      const criteria = (interactionData.find((data) => data.appId === appId)?.criteria as TargetingCriteria) ?? [];
      if (
        !criteria ||
        !unifiedTargeting ||
        isEmpty(unifiedTargeting) ||
        !unifiedAttributes ||
        isEmpty(unifiedAttributes)
      ) {
        return '--';
      }
      const segments = injestCriteria({ criteria, unifiedTargeting, unifiedAttributes });
      if (segments.length === 0) {
        return '--';
      }
      if (segments.length === 1) {
        return (
          <CriteriaDescription
            interactionType={type}
            criteria={criteria}
            unifiedTargeting={unifiedTargeting}
            unifiedAttributes={unifiedAttributes}
            isAppSection
          />
        );
      }
      return `${segments.length} Segments`;
    },
    [interactionData, unifiedTargeting, unifiedAttributes, type],
  );

  const getHeadersForSelfTargeting = useCallback(() => {
    if (selfTargeting === true || isPrompt) {
      return (
        <>
          <th className={bem('table', 'column-flex')}>
            <div className={bem('table-title')}>Who</div>
          </th>
          <th className={bem('table', 'column-flex')}>
            <div className={bem('table-title')}>Where</div>
          </th>
        </>
      );
    }
    if (selfTargeting === false) {
      return (
        <th className={bem('table', 'column-flex')}>
          <div className={bem('table-title')}>Publish</div>
        </th>
      );
    }
    return null;
  }, [selfTargeting]);

  const getContentForSelfTargeting = useCallback(
    (appId: string) => {
      if (selfTargeting === true || isPrompt) {
        return (
          <>
            <td className={bem('who')}>{getCriteria(appId)}</td>
            <td className={bem('where')}>{getCodePoints(appId)}</td>
          </>
        );
      }
      if (selfTargeting === false) {
        return <td className={bem('publish')}>Available to be launched via Love Dialog or Prompt</td>;
      }
      return null;
    },
    [selfTargeting, interactionData],
  );

  const onBackAppsTab = useCallback(
    () => (!isLocked ? setSection(InteractionSection.Apps) : null),
    [isLocked, setSection],
  );

  const isBoolSelfTargeting = typeof selfTargeting === 'boolean';

  return (
    <div className={bem()}>
      <Subtitle title="Apps" />
      <table className={bem('table')}>
        <thead>
          <tr>
            {columnTitles.map((column, index) => (
              <th
                key={column.key}
                className={bem('table', index === 1 && isBoolSelfTargeting ? 'column-fixed' : 'column-flex')}
              >
                <div
                  className={bem('table-title', column.sortable ? 'sortable' : '')}
                  onClick={() => onSortClick(column.key as keyof OrganizationApp)}
                >
                  {column.title}
                  {column.sortable && (
                    <Icon
                      name="arrowDown"
                      className={classNames(bem('sort-icon'), {
                        [bem('sort-icon', 'active-asc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.asc,
                        [bem('sort-icon', 'active-desc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.desc,
                      })}
                    />
                  )}
                </div>
              </th>
            ))}
            {getHeadersForSelfTargeting()}
          </tr>
        </thead>
      </table>
      <div className={bem('scrollable-table')}>
        <table className={bem('table')}>
          <tbody>
            {sortedApps.map((app) => (
              <tr key={app.id}>
                <td>
                  <div className={bem('app-name')}>
                    <img className={bem('app-name', 'icon')} src={app.icon_url || app.iconUrl} alt={app.title} />
                    <span className={bem('app-name', 'title')}>{app.title}</span>
                  </div>
                </td>
                <td className={bem('app-platform', selfTargeting !== null ? 'fixed-column' : '')}>{app.platform}</td>
                {getContentForSelfTargeting(app.id)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={bem('info-section')}>
        <div className={classNames(bem('info-section', 'manage'), { locked: isLocked })} onClick={onBackAppsTab}>
          <Icon name="pencil" />
          <span>Manage Apps</span>
        </div>
        <div className={bem('info-section', 'when')}>
          <p>When:&nbsp;</p>
          <span>
            {!startTime || !selfTargeting ? '--' : Time.create.utc(startTime).format(Time.FORMAT.DATE_TIME_UTC)}
          </span>
        </div>
      </div>
    </div>
  );
};
