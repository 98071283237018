import { Platform } from 'src/types/core';

export const THIS_APP = 'This app';

type FilteredOptionElement = { value: Platform | string; label: string };

export type FilteredOptionValue = FilteredOptionElement['value'];

export const filteredOptions: FilteredOptionElement[] = [
  { value: THIS_APP, label: THIS_APP },
  { value: Platform.iOS, label: 'iOS Apps' },
  { value: Platform.Android, label: 'Android Apps' },
  { value: Platform.Web, label: 'Websites' },
];
