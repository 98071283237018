import { AppEventList } from 'src/reducers/events';
import { OrganizationApp } from 'src/reducers/organizations';
import { TargetingCriteria } from 'src/types/core';
import { CriteriaUnifiedAttributes, CriteriaUnifiedTargeting } from '../types';
import { isAppCompatibleWithRule } from './app-compatibility';
import { convertToCriteria, injestCriteria } from './parse';

export const filterIncompatibleCriteria = ({
  app,
  criteria,
  multipleEvents,
  unifiedTargeting,
  unifiedAttributes,
}: {
  app: OrganizationApp;
  criteria: TargetingCriteria;
  multipleEvents: AppEventList[];
  unifiedTargeting: CriteriaUnifiedTargeting;
  unifiedAttributes: CriteriaUnifiedAttributes;
}): TargetingCriteria => {
  let segments = injestCriteria({
    criteria,
    unifiedTargeting,
    unifiedAttributes,
  });

  if (segments.length !== 0) {
    segments = segments.filter(({ rules }) =>
      rules.every((rule) =>
        isAppCompatibleWithRule({ app, rule, multipleEvents, unifiedTargeting, unifiedAttributes }),
      ),
    );

    if (segments.length === 0) {
      return { $or: [] };
    }
  }

  return convertToCriteria(segments);
};
