import { createSelector } from 'reselect';
import { State } from 'src/reducers';
import { PromptsState } from 'src/reducers/prompts-multi-apps';

const withPromptId = (_: State, prompId: string) => prompId;

export const getPrompts = (state: State): PromptsState => state.promptsMultiApps || [];

export const getPromptList = createSelector(
  getPrompts,
  (prompts) => prompts.prompts || []
);

export const getPromptsListIsLoading = createSelector(
  getPrompts,
  (prompts) => prompts.loading
);
export const getPromptById = createSelector(
  [getPromptList, withPromptId],
  (prompts, prompId) => prompts.find((item) => item.id === prompId)
);

export const getLoadingPromptById = createSelector(
  [getPrompts, withPromptId],
  ({ loadingIds }, prompId) => loadingIds.includes(prompId)
);

export const getActivationInteractionIds = createSelector([getPrompts], ({ activatingIds }) => activatingIds);
