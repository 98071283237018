import { isEmpty } from 'ramda';
import { OrganizationApp } from 'src/reducers/organizations';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { AppInteractionData, Platform, SdkVersion } from 'src/types/core';

export type ExtendedOrganizationApp = OrganizationApp & { isWarningVersion?: boolean };

const MAU_FOR_WEB_PLATFORM = -1;

export const ALL_PLATFORMS_OPTION = 'All';

export const getAppLatestSDKVersion = (app: OrganizationApp) =>
  (app.latestSDKVersion && Number(app.latestSDKVersion.split('.').slice(0, 2).join('.'))) || 0;

export const getImtSDKVersionNumber = (imtSdkVersion: SdkVersion) => Number(imtSdkVersion.replace('v', ''));

export const getFormattedMau = (app: OrganizationApp, isWeb: boolean) => {
  if (isWeb) {
    return MAU_FOR_WEB_PLATFORM;
  }

  return app.mau ? Math.round(app.mau) : 0;
};

export const getExtendedOrgApp = (app: OrganizationApp, imtSdkVersion?: SdkVersion): ExtendedOrganizationApp => {
  const isWeb = app.platform === Platform.Web;
  const isWarningVersion =
    !isWeb && imtSdkVersion && getAppLatestSDKVersion(app) < getImtSDKVersionNumber(imtSdkVersion);

  return {
    ...app,
    mau: getFormattedMau(app, isWeb),
    isWarningVersion,
  };
};

export const getIsAppTargetingFromAnotherInteraction = (
  interactionData: AppInteractionData[],
  app: OrganizationApp,
) => {
  const { codePoints = [], triggeredBy = [] } = interactionData.find(({ appId }) => appId === app.id) || {};
  if (isEmpty(codePoints) || isEmpty(triggeredBy)) {
    return false;
  }

  return SurveyMultiAppsModel.isFromLoveDialog(codePoints);
};

export const getAppsToSelect = (
  orgApps: OrganizationApp[],
  selectedPlatforms: string[],
  interactionData: AppInteractionData[],
  appId: string,
  isNewInteraction: boolean,
): OrganizationApp[] => {
  let appsToSelect = orgApps;
  const hasSelectedAll = selectedPlatforms.includes(ALL_PLATFORMS_OPTION);

  if (!hasSelectedAll) {
    appsToSelect = orgApps.filter(
      (app) =>
        selectedPlatforms.includes(app.platform) || getIsAppTargetingFromAnotherInteraction(interactionData, app),
    );
  }

  const currentApp = orgApps.find((app) => app.id === appId);
  if (currentApp && isNewInteraction) {
    const isAppSelected = appsToSelect.some((app) => app.id === appId);

    if (!hasSelectedAll && !isAppSelected && selectedPlatforms.length === 0) {
      appsToSelect = [currentApp];
    }

    if (!isAppSelected && (hasSelectedAll || selectedPlatforms.length > 0)) {
      appsToSelect = isNewInteraction ? [currentApp, ...appsToSelect] : appsToSelect;
    }
  }

  return appsToSelect;
};
