import React from 'react';

import { bemPrefix } from 'src/utils';
import { ExtendedOrganizationApp } from '../select/searchable-select';
import { HoverTooltip } from '../hover-tooltip/hover-tooltip';
import { PopoverPlacement } from '../popover/simple-popover';

import './app-info.scss';

const bem = bemPrefix('imt-app-info');

export const AppInfo: React.FC<{ app: ExtendedOrganizationApp }> = ({ app }) => {
  const isArchieved = app.hasArchivedEvent ? 'archived' : '';
  return (
    <HoverTooltip
      content={'This event has been archived for this app'}
      placement={PopoverPlacement.top}
      className={bem('tooltip', !app.hasArchivedEvent ? 'disabled' : '')}
    >
      <div key={app.id} className={bem('', isArchieved)}>
        <img className={bem('icon', isArchieved)} src={app.icon_url} alt={app.title} />
        <span className={bem('name', isArchieved)}>{app.title}</span>
        <span className={bem('platform', isArchieved)}>{app.platform}</span>
      </div>
    </HoverTooltip>
  );
};
