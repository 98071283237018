import { Action } from 'redux';
import { DispatchFunc } from 'src/reducers';
import { AppData, CustomDataAttribute, CustomDataEntity, ReachResp, SLReachResp } from 'src/reducers/apps.types';
import { OrganizationApp } from 'src/reducers/organizations';
import { GetAttrResp, UpdAttrPayload } from 'src/services/attributes-service-v2';

export enum AppDataActions {
  FETCH_APP_DATA = 'FETCH_APP_DATA',
  FETCH_APP_DATA_SUCCESS = 'FETCH_APP_DATA_SUCCESS',
  FETCH_APP_DATA_ERROR = 'FETCH_APP_DATA_ERROR',

  SAVE_APP_DATA = 'SAVE_APP_DATA',
  SAVE_APP_DATA_SUCCESS = 'SAVE_APP_DATA_SUCCESS',
  SAVE_APP_DATA_ERROR = 'SAVE_APP_DATA_ERROR',

  SET_APP_PRIMARY_CID_KEY = 'SET_APP_PRIMARY_CID_KEY',
  SET_APP_PRIMARY_CID_KEY_SUCCESS = 'SET_APP_PRIMARY_CID_KEY_SUCCESS',
  SET_APP_PRIMARY_CID_KEY_ERROR = 'SET_APP_PRIMARY_CID_KEY_ERROR',

  GET_SUGGESTED_ATTRIBUTES_PENDING = 'GET_SUGGESTED_ATTRIBUTES_PENDING',
  GET_SUGGESTED_ATTRIBUTES_SUCCESS = 'GET_SUGGESTED_ATTRIBUTES_SUCCESS',
  GET_SUGGESTED_ATTRIBUTES_FAILURE = 'GET_SUGGESTED_ATTRIBUTES_FAILURE',

  GET_ATTRIBUTES_PENDING = 'GET_ATTRIBUTES_PENDING',
  GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS',
  GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE',

  GET_MULTI_APP_ATTRIBUTES_PENDING = 'GET_MULTI_APP_ATTRIBUTES_PENDING',
  GET_MULTI_APP_ATTRIBUTES_SUCCESS = 'GET_MULTI_APP_ATTRIBUTES_SUCCESS',
  GET_MULTI_APP_ATTRIBUTES_FAILURE = 'GET_MULTI_APP_ATTRIBUTES_FAILURE',

  UPDATE_ATTRIBUTES_PENDING = 'UPDATE_ATTRIBUTES_PENDING',
  UPDATE_ATTRIBUTES_SUCCESS = 'UPDATE_ATTRIBUTES_SUCCESS',
  UPDATE_ATTRIBUTES_FAILURE = 'UPDATE_ATTRIBUTES_FAILURE',

  FETCH_SL_REACH_SUCCESS = 'FETCH_SL_REACH_SUCCESS',
  FETCH_SL_REACH_ERROR = 'FETCH_SL_REACH_ERROR',

  FETCH_RC_REACH_SUCCESS = 'FETCH_RC_REACH_SUCCESS',
  FETCH_RC_REACH_ERROR = 'FETCH_RC_REACH_ERROR',

  FETCH_RICH_TEXT_REACH_SUCCESS = 'FETCH_RICH_TEXT_REACH_SUCCESS',
  FETCH_RICH_TEXT_REACH_ERROR = 'FETCH_RICH_TEXT_REACH_ERROR',

  FETCH_INITIATOR_REACH_SUCCESS = 'FETCH_INITIATOR_REACH_SUCCESS',
  FETCH_INITIATOR_REACH_ERROR = 'FETCH_INITIATOR_REACH_ERROR',

  GET_MAU_AND_LATEST_SDK_VERSIONS_PENDING = 'GET_MAU_AND_LATEST_SDK_VERSIONS_PENDING',
  GET_MAU_AND_LATEST_SDK_VERSIONS_SUCCESS = 'GET_MAU_AND_LATEST_SDK_VERSIONS_SUCCESS',
  GET_MAU_AND_LATEST_SDK_VERSIONS_FAILURE = 'GET_MAU_AND_LATEST_SDK_VERSIONS_FAILURE',
}

export type AppCustomData = (CustomDataAttribute & { key: string; entity: CustomDataEntity })[];
export type CustomDataByApp = Record<string, AppCustomData>;

export type AppDataReducerAction = Action<AppDataActions> & {
  payload:
    | Partial<AppData>
    | SLReachResp
    | ReachResp
    | Record<string, GetAttrResp['keys']>
    | { body: UpdAttrPayload }
    | { personKeys: string[]; deviceKeys: string[] }
    | { appId: string }
    | { orgId: string; appIds?: string[] }
    | { orgId: string; customDataByApp: CustomDataByApp }
    | OrganizationApp[];
  meta?: { appId?: string; orgId?: string };
  error?: Error;
};

export type AppDataDispatch = DispatchFunc<AppDataReducerAction>;
