import { Platform } from 'src/types/core';
import { CriteriaRuleType } from '../types';

export type CriteriaRuleKind = {
  group?: string;
  value: string;
  label: string;
  className: string;
  platform?: Platform[];
  type?: CriteriaRuleType;
  limit?: number;
};

const defaultRules: CriteriaRuleKind[] = [
  {
    group: 'Fan Signals',
    value: 'Fan',
    label: 'New Fan',
    className: 'fan',
    platform: [Platform.Android, Platform.iOS],
    type: 'fan_signal',
  },
  {
    group: 'Fan Signals',
    value: 'ReclaimedFan',
    label: 'Shifted to Fan',
    className: 'reclaimed-fan',
    platform: [Platform.Android, Platform.iOS],
    type: 'fan_signal',
  },
  {
    group: 'Fan Signals',
    value: 'RepeatFan',
    label: 'Repeat Fan',
    className: 'repeat-fan',
    platform: [Platform.Android, Platform.iOS],
    type: 'fan_signal',
  },
  {
    group: 'Fan Signals',
    value: 'Opportunity',
    label: 'New Risk',
    className: 'opportunity',
    platform: [Platform.Android, Platform.iOS],
    type: 'fan_signal',
  },
  {
    group: 'Fan Signals',
    value: 'LostFan',
    label: 'Shifted to Risk',
    className: 'lost-fan',
    platform: [Platform.Android, Platform.iOS],
    type: 'fan_signal',
  },
  {
    group: 'Fan Signals',
    value: 'RepeatOpportunity',
    label: 'Repeat Risk',
    className: 'repeat-opportunity',
    platform: [Platform.Android, Platform.iOS],
    type: 'fan_signal',
  },
  // 6
  {
    group: 'Events',
    label: 'Has Seen Event',
    value: 'code_point',
    className: 'code-point',
    type: 'parent',
  },
  {
    group: 'Interactions',
    label: 'Has Seen Interaction',
    value: 'interactions',
    className: 'interaction',
    type: 'parent',
  },
  // 8
  {
    group: 'Interactions',
    label: 'Has Responded to Interaction',
    value: 'interaction_response_targeting',
    className: 'interaction_response_targeting',
    type: 'parent',
  },
  {
    group: 'Random Sampling',
    label: 'Percent of customers',
    value: 'random',
    className: 'random-sampling',
    type: 'number',
  },
  {
    group: 'Person & Device Data',
    label: 'Platform',
    value: 'application/platform',
    className: 'platform',
    type: 'platform',
  },
  {
    group: 'Person & Device Data',
    label: 'Carrier',
    value: 'device/carrier',
    className: 'carrier',
    platform: [Platform.Android, Platform.iOS],
    type: 'string',
  },
  // 10
  {
    group: 'Person & Device Data',
    label: 'Country',
    value: 'device/locale_country_code',
    className: 'country',
    platform: [Platform.Android, Platform.iOS],
    type: 'string',
  },
  {
    group: 'Person & Device Data',
    label: 'Language',
    value: 'device/locale_language_code',
    className: 'language',
    type: 'string',
  },
  {
    group: 'Person & Device Data',
    label: 'Device Model',
    value: 'device/model',
    className: 'model',
    platform: [Platform.Android],
    type: 'string',
  },
  {
    group: 'Person & Device Data',
    label: 'Device',
    value: 'device/hardware',
    className: 'hardware',
    platform: [Platform.iOS],
    type: 'string',
  },
  {
    group: 'Person & Device Data',
    label: 'OS Version',
    value: 'device/os_version',
    className: 'os-version',
    platform: [Platform.Android, Platform.iOS],
    type: 'version',
  },
  // Web
  {
    group: 'Person & Device Data',
    label: 'User Agent',
    value: 'device/user_agent',
    className: 'user-agent web',
    platform: [Platform.Web],
    type: 'string',
  },
  {
    group: 'Person & Device Data',
    label: 'OS Name',
    value: 'device/os_name',
    className: 'model',
    platform: [Platform.Web],
    type: 'string',
  },
  // iOS
  {
    group: 'Person & Device Data',
    label: 'App Version', // Application Version
    value: 'application/cf_bundle_short_version_string',
    className: 'application-version ios',
    platform: [Platform.iOS],
    type: 'version',
  },
  // Android
  {
    group: 'Person & Device Data',
    label: 'App Version Code',
    value: 'application/version_code',
    className: 'application-version android',
    platform: [Platform.Android],
    type: 'number',
  },
  {
    group: 'Person & Device Data',
    label: 'App Version Name',
    value: 'application/version_name',
    className: 'application-version android',
    platform: [Platform.Android],
    type: 'version',
  },
  {
    group: 'Person & Device Data',
    label: 'App Build Type',
    value: 'application/debug',
    className: 'debug',
    type: 'boolean',
  },
];

export default defaultRules;
