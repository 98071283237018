import { assocPath, mergeDeepRight, omit } from 'ramda';
import * as actions from 'src/actions/organizations';
import * as targetingActions from 'src/actions/targeting';

const initialState = {
  organizationsLoading: false,
  organizationsError: false,
  orgs: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    /*
      Fetching all organizations
    */
    case actions.FETCH_ORGANIZATIONS_PENDING:
      return {
        ...state,
        organizationsLoading: true,
        organizationsError: false,
      };

    case actions.FETCH_ORGANIZATIONS_SUCCESS: {
      // Convert the incoming array to our keyed structure.
      const newOrgs = (action.payload || []).reduce(
        (acc, org) => ({
          ...acc,
          [org.id]: { ...org, lastUpdated: action.meta.lastUpdated },
        }),
        {},
      );

      return mergeDeepRight(state, {
        organizationsLoading: false,
        organizationsError: false,
        lastUpdated: action.meta.lastUpdated,
        orgs: newOrgs,
      });
    }
    case actions.FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        organizationsLoading: false,
        organizationsError: true,
      };

    /*
      Fetching a single org
    */
    case actions.FETCH_ORGANIZATION_PENDING:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.meta.orgId]: {
            ...state.orgs[action.meta.orgId],
            organizationLoading: true,
            organizationError: false,
          },
        },
      };
    case actions.FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.meta.orgId]: {
            ...state.orgs[action.meta.orgId],
            ...action.payload,
            lastUpdated: action.meta.lastUpdated,
            organizationLoading: false,
            organizationError: false,
          },
        },
      };
    case actions.FETCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.meta.orgId]: {
            ...state.orgs[action.meta.orgId],
            organizationLoading: false,
            organizationError: true,
          },
        },
      };

    /*
      Fetching app data for each org
    */
    case actions.RECEIVE_APPS_FOR_ORG:
      return {
        ...state,
        lastUpdated: action.meta.lastUpdated,
        orgs: {
          ...state.orgs,
          [action.meta.orgId]: {
            ...state.orgs[action.meta.orgId],
            apps: action.payload.reduce((apps, app) => {
              apps.push({ ...app, iconUrl: app.icon_url });
              return apps;
            }, []),
          },
        },
      };
    case actions.RECEIVE_ORGANIZATION_MEMBERS: {
      return assocPath(['orgs', action.meta.orgId, 'members'], action.payload, state);
    }

    case actions.DELETE_ORGANIZATION_SUCCESS: {
      const orgs = omit([action.meta.orgId], state.orgs);
      return {
        ...state,
        orgs,
      };
    }

    case actions.UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.meta.orgId]: {
            ...state.orgs[action.meta.orgId],
            ...action.payload,
            lastUpdated: action.meta.lastUpdated,
            organizationLoading: false,
            organizationError: false,
          },
        },
      };

    case targetingActions.FETCH_UNIFIED_TARGETING_SUCCESS:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          [action.meta.orgId]: {
            ...state.orgs[action.meta.orgId],
            unifiedTargeting: {
              ...state.orgs[action.meta.orgId].unifiedTargeting,
              [action.meta.unifiedInteractionId]: action.payload,
            },
          },
        },
      };

    default:
      return state;
  }
};
