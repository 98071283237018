import { Rest, API } from 'src/api';
import { CustomDataAttribute, CustomDataEntity } from 'src/reducers/apps.types';
import { BaseService } from './base-service';

export type GetAttrResp = {
  keys: Record<string, CustomDataAttribute>;
};

export type CustomDataPayload = CustomDataAttribute & { key: string };

export type UpdAttrPayload = {
  device: CustomDataPayload[];
  person: CustomDataPayload[];
};

export type GetMultiAttrKeyResp = {
  app_id: string;
  keys: Record<string, CustomDataAttribute>;
}[];

export type MultiAttrValues = {
  values: string[];
};

export type GetMultiAttrValueResp = {
  app_id: string;
  keys: Record<string, MultiAttrValues>;
}[];

export class AttributesServiceV2 extends BaseService {
  static getSuggestedAttr(app_id: string) {
    return Rest.httpGet(API.ATTRIBUTES_SERVICE_V2.GET_SUGGESTED_ATTRIBUTES(app_id), {
      errorTitle: '[API] Error Fetching Suggested Attributes',
    }).promise;
  }

  static getAttr(app_id: string, isPerson = true): Promise<GetAttrResp> {
    return Rest.httpGet(API.ATTRIBUTES_SERVICE_V2.GET_ATTRIBUTES(app_id, isPerson), {
      errorTitle: '[API] Error Fetching Attributes',
    }).promise;
  }

  static updAttr(app_id: string, payload: UpdAttrPayload): Promise<UpdAttrPayload> {
    return Rest.httpPut(API.ATTRIBUTES_SERVICE_V2.UPD_ATTRIBUTES(app_id), {
      body: payload,
      errorTitle: '[API] Error Fetching Attributes',
    }).promise;
  }

  static getMultiAppAttrKeys(org_id: string, entity: CustomDataEntity, appIds: string[]): Promise<GetMultiAttrKeyResp> {
    return Rest.httpPost(API.ATTRIBUTES_SERVICE_V2.GET_MULTI_APP_ATTRIBUTES_KEYS(org_id, entity), {
      errorTitle: '[API] Error Fetching Multi-app Attribute Keys',
      body: { apps: appIds },
    }).promise;
  }

  static getMultiAppAttrValues(
    org_id: string,
    entity: CustomDataEntity,
    appIds: string[],
    key: string,
  ): Promise<GetMultiAttrValueResp> {
    return Rest.httpPost(API.ATTRIBUTES_SERVICE_V2.GET_MULTI_APP_ATTRIBUTE_VALUES(org_id, entity, key), {
      errorTitle: '[API] Error Fetching Multi-app Attribute Values',
      body: { apps: appIds },
    }).promise;
  }
}
