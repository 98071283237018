import React, { FC, FormEvent } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bemPrefix } from 'src/utils';
import { AppInteractionData, InteractionTrigger, InteractionType } from 'src/types/core';
import { Button, HoverTooltip, Loading } from 'src/components/molecules';
import { humanizedEventNameList } from 'src/utils/events';
import { GtmID } from 'src/types/google-tag-manager';
import { PopoverPlacement } from 'src/components/molecules/popover/simple-popover';
import { getIsSurveyActivation } from 'src/selectors/surveys-multi-apps.selectors';
import { State } from 'src/reducers';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { PromptModel } from 'src/reducers/prompts-multi-apps';
import { getCurrentUserId } from 'src/selectors/user';
import { getOrganizationApps } from 'src/selectors/organizations';
import { ImtPermisson } from 'src/reducers/imt/imt.model';
import { ImtListStatusModal } from './imt-list-status-modal';

import './imt-list-row.scss';

const bem = bemPrefix('imt-list-row');

export interface ImtListElement {
  id: string;
  active: boolean;
  triggeredBy: InteractionTrigger[];
  interactionData: AppInteractionData[];
  isValidaTargeting: boolean;
  responseCount?: number;
  actionsCount?: number;
  responseRate?: number;
  responseRateCachedTime?: string;
  title?: string;
  name: string;
  updatedAt?: string;
  selfTargeting?: boolean;
  createdBy: string;
}

export interface ImtListRowProps {
  type: InteractionType;
  imtListElement: ImtListElement;
  path: string;
  currentAppId: string;
  handleActivate?: (event: FormEvent<HTMLButtonElement>) => void;
  handleDeactivate?: (event: FormEvent<HTMLButtonElement>) => void;
  handleDelete: (event: FormEvent<HTMLButtonElement>) => void;
}

export const ImtListRow: FC<ImtListRowProps> = ({
  type,
  imtListElement,
  path,
  currentAppId,
  handleDeactivate,
  handleActivate,
  handleDelete,
}) => {
  const isLoading = useSelector((state: State) => getIsSurveyActivation(state, imtListElement.id));
  const userId = useSelector((state: State) => getCurrentUserId(state));
  const organizationApps = useSelector((state: State) => getOrganizationApps(state)) || [];

  const triggeredBy = imtListElement.triggeredBy || [];
  const isActive = imtListElement.interactionData.some(({ active }) => active);
  const displayRate = imtListElement.responseRate ? `${(imtListElement.responseRate * 100).toPrecision(3)}%` : '0%';

  const isSurvey = type === InteractionType.Survey;
  const isPrompt = type === InteractionType.TextModal;

  const userPermisson = (isSurvey ? SurveyMultiAppsModel : PromptModel).getUserPermissions(
    { interactionData: imtListElement.interactionData, createdBy: imtListElement.createdBy },
    userId,
    organizationApps.map(({ id }) => id),
  );

  const hasActionBtns = userPermisson !== ImtPermisson.HAS_ACCESS_TO_SOME_APPS;

  const renderStatus = () => {
    if (triggeredBy.length > 0) {
      return (
        <span className="active ratings-prompt">
          {triggeredBy.map((interaction) => {
            switch (interaction.type) {
              case 'TextModal':
                return (
                  <a key={interaction.id} href={`/apps/${currentAppId}/prompts/${interaction.id}`}>
                    Prompt
                  </a>
                );
              case 'EnjoymentDialog':
                return (
                  <a key={interaction.id} href={`/apps/${currentAppId}/love-prompt`} title="Love Dialog">
                    Love Dialog
                  </a>
                );
              default:
                return interaction.type;
            }
          })}
        </span>
      );
    }

    const activeAppsCount = imtListElement.interactionData.filter((int) => int.active).length;
    const inactiveAppsCount = imtListElement.interactionData.length - activeAppsCount;
    const completedAppsCount = imtListElement.interactionData.filter(
      (int) => int.responseCount && int.responseCount > 0 && !int.active,
    ).length;

    if (isActive) {
      return (
        <div className={bem('status-block')}>
          <span className={bem('status-block', 'active')}>Active</span>
          <span className={bem('status-block__hint')}>
            {`In ${activeAppsCount} ${activeAppsCount === 1 ? 'App' : 'Apps'}`}
          </span>
        </div>
      );
    }

    if (imtListElement.responseCount && imtListElement.responseCount > 0) {
      return (
        <div className={bem('status-block')}>
          <span className={bem('status-block', 'completed')}>Completed</span>
          <span className={bem('status-block__hint')}>
            {`In ${completedAppsCount} ${completedAppsCount === 1 ? 'App' : 'Apps'}`}
          </span>
        </div>
      );
    }

    return (
      <div className={bem('status-block')}>
        <span className={bem('status-block', 'inactive')}>{isPrompt ? 'Paused' : 'Inactive'}</span>
        <span className={bem('status-block__hint')}>
          {`In ${inactiveAppsCount} ${inactiveAppsCount === 1 ? 'App' : 'Apps'}`}
        </span>
      </div>
    );
  };

  const getShownEvents = () => {
    const eventsSet = new Set<string>();
    if (imtListElement.selfTargeting) {
      imtListElement.interactionData.forEach((int) =>
        ((int.codePoints as string[]) || []).forEach((cp) => eventsSet.add(cp)),
      );
    } else {
      (
        (imtListElement.interactionData.find(({ appId }) => appId === currentAppId)?.codePoints as string[]) || []
      ).forEach((cp) => eventsSet.add(cp));
    }

    const filteredEvents = Array.from(eventsSet);
    return filteredEvents.length > 0 ? humanizedEventNameList(Array.from(eventsSet)) : '--';
  };

  const isActivateUnabled = imtListElement.interactionData.some((int) => int.codePoints && int.codePoints.length > 0);

  const isLinkedToLdNoSection = imtListElement.interactionData.some(
    (int) => int.codePoints && int.codePoints.includes('enjoyment_dialog.no'),
  );

  const itemName = imtListElement.title || imtListElement.name;
  return (
    <>
      {isLoading && <Loading className={bem('loader')} title="" isSmall />}
      <tr key={imtListElement.id} className={`${bem()} ${isLoading ? bem('loading') : ''}`}>
        <td className={bem('name')}>
          {isLoading ? (
            <span>{itemName}</span>
          ) : (
            <NavLink
              className="name link"
              gtm-id={isSurvey ? GtmID.InteractionSurveySelect : GtmID.InteractionNoteSelect}
              to={path}
            >
              {itemName}
            </NavLink>
          )}
        </td>
        <td className={bem('updated-at')}>
          <abbr title={imtListElement.updatedAt}>{moment(imtListElement.updatedAt).fromNow()}</abbr>
        </td>
        <td className={bem('events')}>{getShownEvents()}</td>
        {isSurvey && <td className={bem('response-count')}>{imtListElement.responseCount}</td>}
        {isPrompt && <td className={bem('actions-count')}>{imtListElement.actionsCount}</td>}
        {isSurvey && (
          <td className={bem('response-rate')}>
            <>
              {imtListElement.responseRateCachedTime ? (
                <div>
                  <span className="rate">{displayRate}</span>
                  <span> as of </span>
                  <span className={bem('timeago')} title={imtListElement.responseRateCachedTime}>
                    {moment(imtListElement.responseRateCachedTime).fromNow()}
                  </span>
                </div>
              ) : (
                <span>Not yet available</span>
              )}
            </>
          </td>
        )}
        <td className={bem('status')}>
          {isLoading ? (
            renderStatus()
          ) : (
            <HoverTooltip
              content={isLoading ? null : <ImtListStatusModal imtApps={imtListElement.interactionData} type={type} />}
              placement={PopoverPlacement.bottomStart}
              className={bem('tooltip')}
            >
              {renderStatus()}
            </HoverTooltip>
          )}
        </td>
        {hasActionBtns && isSurvey && (
          <td className={bem('actions')}>
            {!isActive && (
              <Button
                className={bem('button-action')}
                gtm-id={isSurvey ? GtmID.InteractionSurveyUnArchive : ''}
                data-survey-id={imtListElement.id}
                onClick={handleActivate}
                disabled={!isActivateUnabled || isLoading}
              >
                Unarchive
              </Button>
            )}
            {isActive && triggeredBy.length === 0 && !isLinkedToLdNoSection && (
              <Button
                className={bem('button-action')}
                gtm-id={isSurvey ? GtmID.InteractionSurveyArchive : ''}
                data-survey-id={imtListElement.id}
                onClick={handleDeactivate}
                disabled={isLoading}
              >
                Archive
              </Button>
            )}
          </td>
        )}
        {hasActionBtns && (
          <td className={bem('delete-action')}>
            <Button
              className={bem('button-action')}
              gtm-id={isSurvey ? GtmID.InteractionSurveyDelete : GtmID.InteractionNoteDelete}
              data-survey-id={imtListElement.id}
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          </td>
        )}
      </tr>
    </>
  );
};
