import { Action } from 'redux';
import { PromptActions } from 'src/actions/prompts-multi-apps/prompts-multi-apps.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import { AppInteractionData } from 'src/types/core';

import { initialState, PromptsState } from './prompts-multi-apps.state';
import { PromptMultiAppsItem } from './prompts-multi-apps.types';

export type PromptsMultiAppsReducerAction = Action<
  PromptActions | typeof UPDATE_CURRENT_APP_PENDING | typeof UPDATE_CURRENT_APP_SUCCESS
> & {
  payload: {
    prompt?: PromptMultiAppsItem;
    prompts?: PromptMultiAppsItem[];
    promptId?: string;
    appId?: string;
    errorMessage?: string;
    interactionData?: AppInteractionData[];
  };
  error?: Error;
};

export const promptsMultiAppsReducer = (
  state: PromptsState = initialState,
  action: PromptsMultiAppsReducerAction = {} as PromptsMultiAppsReducerAction,
): PromptsState => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case PromptActions.GET_PROMPT_MULTI_APPSS_PENDING: {
      return { ...state, loading: true };
    }
    case PromptActions.GET_PROMPT_MULTI_APPSS_SUCCESS: {
      const { prompts = [] } = action.payload;
      return {
        ...state,
        prompts,
        loading: false,
      };
    }

    case PromptActions.GET_PROMPT_MULTI_APPSS_ERROR: {
      return { ...state, loading: false };
    }

    case PromptActions.ACTIVATE_PROMPT_MULTI_APPS_PENDING: {
      const { interactionData } = action.payload;
      return {
        ...state,
        activatingIds: [
          ...state.activatingIds,
          ...(interactionData || []).map(({ interactionId }) => interactionId as string),
        ],
      };
    }

    case PromptActions.ACTIVATE_PROMPT_MULTI_APPS_ERROR: {
      const { interactionData } = action.payload;
      const interactionDataSet = new Set((interactionData || []).map(({ interactionId }) => interactionId as string));
      return {
        ...state,
        activatingIds: state.activatingIds.filter((id) => !interactionDataSet.has(id)),
      };
    }

    case PromptActions.GET_PROMPT_MULTI_APPS_PENDING: {
      const { promptId = '' } = action.payload;
      return {
        ...state,
        loadingIds: [...state.loadingIds, promptId],
      };
    }

    case PromptActions.GET_PROMPT_MULTI_APPS_SUCCESS:
    case PromptActions.CREATE_PROMPT_MULTI_APPS_SUCCESS:
    case PromptActions.UPDATE_PROMPT_MULTI_APPS_SUCCESS:
    case PromptActions.ACTIVATE_PROMPT_MULTI_APPS_SUCCESS: {
      const { prompt } = action.payload as {
        prompt: PromptMultiAppsItem;
      };
      const interactionDataSet = new Set(
        (prompt.interactionData || []).map(({ interactionId }) => interactionId as string),
      );
      const data = [...state.prompts];
      const idx = data.findIndex((item) => item.id === prompt.id);
      if (idx >= 0) {
        data.splice(idx, 1, prompt);
      } else {
        data.unshift(prompt);
      }
      return {
        ...state,
        prompts: data,
        loadingIds: state.loadingIds.filter((id) => id !== prompt.id),
        activatingIds: state.activatingIds.filter((id) => !interactionDataSet.has(id)),
      };
    }

    case PromptActions.GET_PROMPT_MULTI_APPS_ERROR: {
      const { promptId = '' } = action.payload;
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== promptId),
        activatingIds: state.activatingIds.filter((id) => id !== promptId),
      };
    }

    case PromptActions.DELETE_PROMPT_MULTI_APPS_SUCCESS: {
      const { promptId = '' } = action.payload;
      return {
        ...state,
        prompts: state.prompts.filter((item) => item.id !== promptId),
      };
    }

    default:
      return state;
  }
};
