import { Action } from 'redux';
import { DispatchFunc } from 'src/reducers';
import { ReachResp } from 'src/reducers/apps.types';
import { ReachByAppAndVersion, SdkVersion } from 'src/types/core';

export enum ReachActions {
  FETCH_SDK_VERSION_REACH_BY_APPS_PENDING = 'FETCH_SDK_VERSION_REACH_BY_APPS_PENDING',
  FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS = 'FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS',
  FETCH_SDK_VERSION_REACH_BY_APPS_FAILURE = 'FETCH_SDK_VERSION_REACH_BY_APPS_FAILURE',

  FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_PENDING = 'FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_PENDING',
  FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_SUCCESS = 'FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_SUCCESS',
  FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_FAILURE = 'FETCH_ALL_SDK_VERSIONS_REACH_BY_APPS_FAILURE',
}

export type ReachReducerAction = Action<ReachActions> & {
  payload: ReachResp[] | string[] | { appIds: string[] } | ReachByAppAndVersion;
  meta?: {
    orgId: string;
    sdkVersion?: SdkVersion;
    appIds?: string[];
  };
  error?: Error;
};

export type ReachDispatch = DispatchFunc<ReachReducerAction>;
