const nthIndex = (str: string, pattern: string, n: number) => {
  let index = -1;
  if (!str) {
    return index;
  }
  const { length } = str;

  while (n > 0 && index < length) {
    index = str.indexOf(pattern, index);
    if (index < 0) break;

    index += 1;
    n -= 1;
  }

  return index;
};

// Encode Special Characters: # % /
// From: local#app#pageView-stage.www.hongkongdisneyland.com/attractions/big-grizzly-mountain-runaway-mine-cars//invokes/total
// To:   local#app#pageView-stage.www.hongkongdisneyland.com%2Fattractions%2Fbig-grizzly-mountain-runaway-mine-cars%2F/invokes/total
const encodeEventKey = (value: string) => {
  // Find second index of # to split the event name at
  const splitIndex = nthIndex(value, '#', 2);

  if (splitIndex === -1) {
    return value;
  }

  const prefix = value.slice(0, splitIndex + 1);
  const key = value.slice(splitIndex + 1);

  const sanitizedKey = key
    // First replace all encoded characters with their respective characters
    .replace(/%25/g, '%')
    .replace(/%23/g, '#')
    .replace(/%2F/g, '/')
    .replace(/%/g, '%25')
    .replace(/#/g, '%23')
    .replace(/\//g, '%2F')
    .trim();

  return `${prefix}${sanitizedKey}`;
};

export default encodeEventKey;
