import { OrganizationApp } from "src/reducers/organizations";
import { eventNameToHuman } from "src/utils/events";
import { AppEventList } from "src/reducers/events";
import { AppInteractionData } from "src/types/core";
import { ExtendedOrganizationApp, SearchableSelectOption } from "../select";

const idToItem = (id: string): SearchableSelectOption => ({
  id,
  label: id,
  title: id,
  name: id,
});

export const getSelectedItems = (
  selectedIds: string[] | [string, string],
  isMultiAppInteraction: boolean,
  options: SearchableSelectOption[],
  multipleEvents: AppEventList[],
  apps: OrganizationApp[],
  interactionData: AppInteractionData[],
): SearchableSelectOption[] => {
  const multiAppsEvents = selectedIds.map((label) => {
    const appEventsMap = new Map(
      multipleEvents.map((event) => [event.app_id, event.items])
    );
    const appsMap = new Map(
      apps.map((app) => [app.id, app])
    );
    const associatedAppsMap = new Map<string, ExtendedOrganizationApp>();

    // NOTE: Add the app if it has an event with the specified label
    apps.forEach((app) => {
      const events = appEventsMap.get(app.id);
      if (events?.some((event) => event.label === label)) {
        associatedAppsMap.set(app.id, {
          ...app,
          hasArchivedEvent: false,
        });
      }
    });

    // NOTE: Add apps from interactionData if they haven't been added yet and match the conditions for the label
    interactionData.forEach((interaction) => {
      const app = appsMap.get(interaction.appId);
      const isAppValid = app && interaction.codePoints?.includes(label) && !associatedAppsMap.has(app.id);
      if (isAppValid) {
        associatedAppsMap.set(app.id, {
          ...app,
          hasArchivedEvent: true,
        });
      }
    });

    return {
      id: label,
      label: eventNameToHuman(label),
      title: eventNameToHuman(label),
      apps: Array.from(associatedAppsMap.values()),
    };
  });

  const eventOptions: SearchableSelectOption[] | undefined = isMultiAppInteraction ? multiAppsEvents : options;
  const existedOptions = eventOptions?.filter(({ id }) => selectedIds.includes(id));
  return selectedIds.map((id: string) => existedOptions?.find((item) => item.id === id) || idToItem(id));
};
