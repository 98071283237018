import React, { useState } from 'react';
import classNames from 'classnames';
import { bemPrefix } from 'src/utils';

import { Icon } from '../icons';
import { DropDown } from '../dropdown';
import { filteredOptions, FilteredOptionValue, THIS_APP } from './imt-list-search-filter-utils';

import './imt-list-search-filter.scss';

const bem = bemPrefix('imt-filter');

interface ImtFilterProps {
  filteredValue: FilteredOptionValue[];
  setFilteredValue: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ImtFilter: React.FC<ImtFilterProps> = ({ filteredValue, setFilteredValue }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isFiltered = filteredValue.length > 0;

  const handleDropdownToggle = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  };

  const handleSelectFilter = (selectedOption: string) => {
    setFilteredValue((prevState) => {
      if (selectedOption === THIS_APP) {
        return prevState.includes(THIS_APP) ? [] : [THIS_APP];
      }

      if (prevState.includes(THIS_APP)) {
        return [selectedOption];
      }

      return prevState.includes(selectedOption)
        ? prevState.filter((item) => item !== selectedOption)
        : [...prevState, selectedOption];
    });
  };

  const placeholder = (
    <>
      <Icon name="filter" className={classNames('filter-icon', { open: isDropdownOpen })} />
      <span className={classNames('title', { open: isDropdownOpen })}>Filter</span>
      {isFiltered && <div className={'count'}>{filteredValue.length}</div>}
    </>
  );

  return (
    <div className={`${bem()} ${isDropdownOpen ? 'open' : ''}`}>
      <DropDown
        options={filteredOptions}
        placeholder={placeholder}
        onSelect={handleSelectFilter}
        selectedValue={filteredValue}
        customIcon="arrowDown"
        onDropdownOpen={handleDropdownToggle}
        customCloseDropdown
      />
    </div>
  );
};
