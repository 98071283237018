import { useMemo } from 'react';
import { CustomDataByApp } from 'src/actions/app-data';
import { CustomDataEntity } from 'src/reducers/apps.types';
import { CriteriaUnifiedAttributeKey, CriteriaUnifiedAttributes } from './targeting/multi-app-segment-builder/types';

const getKeyValue = (key: string) => (key.startsWith('custom_data.') ? key : `custom_data.${key}`);

export const useUnifiedAttributes = (customDataByApp?: CustomDataByApp): CriteriaUnifiedAttributes => {
  return useMemo(() => {
    const attributeMap: Record<CustomDataEntity, Record<string, CriteriaUnifiedAttributeKey>> = {
      device: {},
      person: {},
    };

    for (const [appId, appCustomData] of Object.entries(customDataByApp || {})) {
      for (const { key, entity, ...rest } of appCustomData) {
        (attributeMap[entity][key] ??= { app_ids: [], key: getKeyValue(key), name: key, ...rest }).app_ids.push(appId);
      }
    }

    return {
      device: Object.values(attributeMap.device),
      person: Object.values(attributeMap.person),
    };
  }, [customDataByApp]);
};
