import React, { FC } from 'react';
import { OrganizationApp } from 'src/reducers/organizations';
import { AppInteractionData, InteractionType } from 'src/types/core';
import { AppInfo } from 'src/components/molecules/imt-app-info';
import { useSelector } from 'react-redux';
import { getOrganizationApps } from 'src/selectors/organizations';
import { bemPrefix } from 'src/utils';

import './imt-list-status-modal.scss';

const bem = bemPrefix('imt-list-status-modal');

export const ImtListStatusModal: FC<{ imtApps: AppInteractionData[]; type: InteractionType }> = ({ imtApps, type }) => {
  const orgApps = useSelector(getOrganizationApps);

  const activeApps = imtApps
    .filter((app) => app.active && orgApps.some((orgApp) => orgApp.id === app.appId))
    .map(({ appId }) => orgApps.find((app) => app.id === appId)) as OrganizationApp[];

  const inActiveApps = imtApps
    .filter((app) => !app.responseCount && !app.active && orgApps.some((orgApp) => orgApp.id === app.appId))
    .map(({ appId }) => orgApps.find((app) => app.id === appId)) as OrganizationApp[];

  const completedApps = imtApps
    .filter(
      (app) =>
        app.responseCount && app.responseCount > 0 && !app.active && orgApps.some((orgApp) => orgApp.id === app.appId),
    )
    .map(({ appId }) => orgApps.find((app) => app.id === appId)) as OrganizationApp[];

  const hasActiveApps = activeApps.length > 0;
  const hasInactiveApps = inActiveApps.length > 0;
  const hasCompletedApps = completedApps.length > 0 && type === InteractionType.Survey;

  return (
    <div className={bem()}>
      <div>
        <p>Currently Active In:</p>
        {hasActiveApps && activeApps.length > 0 ? (
          <>
            {activeApps.map((app) => (
              <div className={bem('app')}>
                <AppInfo key={app.id} app={app} />
              </div>
            ))}
          </>
        ) : (
          <p>--</p>
        )}
      </div>
      <div>
        <p>{type === InteractionType.TextModal ? 'Paused In:' : 'Inactive'}</p>
        {hasInactiveApps && inActiveApps.length > 0 ? (
          <>
            {inActiveApps.map((app) => (
              <div key={app.id} className={bem('app')}>
                <AppInfo key={app.id} app={app} />
              </div>
            ))}
          </>
        ) : (
          <p>--</p>
        )}
      </div>
      {hasCompletedApps && (
        <div>
          <p>Completed In:</p>
          <>
            {completedApps.map((app) => (
              <div className={bem('app')}>
                <AppInfo key={app.id} app={app} />
              </div>
            ))}
          </>
        </div>
      )}
    </div>
  );
};
